<template>
  <div class="insight-page">
    <Breadcrumb
      :items="[
        { url: '/', title: 'Home' },
        { url: '/insights', title: 'Insights' },
        { url: route.fullPath, title: page.elements.title.value ?? '' }
      ]"
      class="d-none d-lg-block"
    />
    <PageContent :sidebar="true" :sidebar-table-of-content="false" :header-sub-navigation="false">
      <template #sidebar>
        <div class="sidebar">
          <div class="insight-page__overview-text-sidebar">
            <SmartLinkStandaloneElement :codename="type.elements.title.codename">
              <h1>{{ data.title }}</h1>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.subtitle.codename">
              <p>
                <b>{{ data.subtitle }}</b>
              </p>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement
              v-if="!isEmpty(page.elements.content.value)"
              :codename="type.elements.content.codename"
            >
              <RichText :value="data.content" v-if="isMobile" />
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement v-else :codename="type.elements.content.codename">
              <div class="rich-text-content" v-if="isMobile">
                <div v-html="page.elements.richTextInterpreterRichText.value"></div>
              </div>
            </SmartLinkStandaloneElement>
          </div>
          <SmartLinkStandaloneElement
            :codename="type.elements.call_to_action.codename"
            v-if="data.cta"
          >
            <CallToAction
              v-for="cta in pageStore.getListAs<CallToActionModel>(
                page?.elements.callToAction.value
              )"
              :key="cta?.system?.id"
              :model="cta"
              context-class="btn btn-manatt-black"
            />
          </SmartLinkStandaloneElement>
          <div v-if="data.publishingDate" class="published-date">
            <template v-if="!isEventOrWebinar">
              <h3>Published On</h3>
              <p>{{ prettyDate(data.publishingDate) }}</p>
            </template>
          </div>
          <SmartLinkStandaloneElement :codename="type.elements.event_start.codename">
            <EventDetails
              v-if="data.eventStart.value && isEventOrWebinar"
              :event-start="data.eventStart"
              :event-end="data.eventEnd"
              :show-subscribe-btn="data.showSubscribeBtn"
            />
          </SmartLinkStandaloneElement>
          <div
            v-if="
              data.author?.length > 0 || data.presenters?.length > 0 || data.speakers?.length > 0
            "
          >
            <SmartLinkStandaloneElement :codename="type.elements.authors.codename">
              <template v-if="data.author && data.author.length > 0">
                <h3>Author{{ data.author.length > 1 ? 's' : '' }}</h3>
                <LazyPeopleCard
                  v-for="person in data.author"
                  :key="person.system.id"
                  :item="
                    {
                      alias: person.elements.peopleAlias?.value,
                      displayName: person.elements.displayName?.value,
                      email: person.elements.email?.value,
                      peopleImageUrl: person.elements.peopleImage?.value[0]?.url,
                      location: person.elements.peopleLocation.value,
                      phone: person.elements.peoplePhoneNumber?.value,
                      title: 'Author',
                      position: [person.elements.peopleTitle.value[0]?.name],
                      id: person.system.id,
                      vCardUrl: person.elements.vcard.value[0]?.url
                    } as SearchResultPeopleItem
                  "
                />
              </template>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.presenters.codename">
              <template v-if="data.presenters && data.presenters.length > 0">
                <h3>Presenter{{ data.presenters.length > 1 ? 's' : '' }}</h3>
                <LazyPeopleCard
                  v-for="person in data.presenters"
                  :key="person.system.id"
                  class="people-card"
                  :item="
                    {
                      alias: person.elements.peopleAlias?.value,
                      displayName: person.elements.displayName?.value,
                      email: person.elements.email?.value,
                      peopleImageUrl: person.elements.peopleImage?.value[0]?.url,
                      location: person.elements.peopleLocation.value,
                      phone: person.elements.peoplePhoneNumber?.value,
                      title: 'Presenter',
                      position: [person.elements.peopleTitle.value[0]?.name],
                      id: person.system.id,
                      vCardUrl: person.elements.vcard.value[0]?.url
                    } as SearchResultPeopleItem
                  "
                />
              </template>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.speakers.codename">
              <template v-if="data.speakers && data.speakers.length > 0">
                <h3>Speaker{{ data.speakers.length > 1 ? 's' : '' }}</h3>
                <LazyPeopleCard
                  v-for="person in data.speakers"
                  :key="person.system.id"
                  :item="
                    {
                      alias: person.elements.peopleAlias?.value,
                      displayName: person.elements.displayName?.value,
                      email: person.elements.email?.value,
                      peopleImageUrl: person.elements.peopleImage?.value[0]?.url,
                      location: person.elements.peopleLocation.value,
                      phone: person.elements.peoplePhoneNumber?.value,
                      title: 'Speaker',
                      position: [person.elements.peopleTitle.value[0]?.name],
                      id: person.system.id,
                      vCardUrl: person.elements.vcard.value[0]?.url
                    } as SearchResultPeopleItem
                  "
                />
              </template>
            </SmartLinkStandaloneElement>
          </div>
          <div v-else>
            <SmartLinkStandaloneElement :codename="type.elements.related_people.codename">
              <template v-if="data.relatedPeople && data.relatedPeople.length > 0">
                <h3>Related People</h3>
                <LazyPeopleCard
                  v-for="person in data.relatedPeople"
                  :key="person.system.id"
                  :item="
                    {
                      alias: person.elements.peopleAlias?.value,
                      displayName: person.elements.displayName?.value,
                      email: person.elements.email?.value,
                      peopleImageUrl: person.elements.peopleImage?.value[0]?.url,
                      location: person.elements.peopleLocation.value,
                      phone: person.elements.peoplePhoneNumber?.value,
                      title: 'Speaker',
                      position: [person.elements.peopleTitle.value[0]?.name],
                      id: person.system.id,
                      vCardUrl: person.elements.vcard.value[0]?.url
                    } as SearchResultPeopleItem
                  "
                />
              </template>
            </SmartLinkStandaloneElement>
          </div>
        </div>
        <LazyShareButtons />
        <SmartLinkStandaloneElement :codename="type.elements.enable_subscribe_modal.codename">
          <button
            v-if="data.showSubscribeBtn"
            @click="subscriptionModal.toggleModal()"
            class="btn btn-manatt-black register-button"
          >
            Subscribe
          </button>
        </SmartLinkStandaloneElement>
      </template>
      <template #content>
        <div class="main-content">
          <div class="container insight-page__overview-text">
            <SmartLinkStandaloneElement :codename="type.elements.title.codename">
              <h1>{{ data.title }}</h1>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.subtitle.codename">
              <p>
                <b>{{ data.subtitle }}</b>
              </p>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement
              :codename="type.elements.content.codename"
              v-if="!isEmpty(page.elements.content.value)"
            >
              <RichText :value="data.content" v-if="!isMobile" />
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.content.codename" v-else>
              <div class="rich-text-content" v-if="!isMobile">
                <div v-html="page.elements.richTextInterpreterRichText.value"></div>
              </div>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement
              :codename="type.elements.call_to_action.codename"
              v-if="data.cta"
            >
              <CallToAction
                v-for="cta in pageStore.getListAs<CallToActionModel>(
                  page?.elements.callToAction.value
                )"
                :key="cta?.system?.id"
                :model="cta"
                context-class="btn btn-manatt-black content-cta"
              />
            </SmartLinkStandaloneElement>
          </div>
        </div>
      </template>
    </PageContent>
  </div>
</template>
<script setup lang="ts">
import type { CallToActionModel, InsightPageModel, PeoplePageModel } from '~/models'
import { contentTypes, taxonomies } from '~/models'
import { usePardotModalStore } from '~/stores/modals/pardot-modal'
import type { SearchResultPeopleItem } from '~/types/SearchResultItem'
const type = contentTypes.insight_page

const route = useRoute()
const pageStore = usePageStore()
const siteStore = useSiteStore()
const page = pageStore.page as InsightPageModel
const subscriptionModal = usePardotModalStore()
const { isMobile } = useDetectMobile()
const { scrollToElement } = useSmoothScroll()

subscriptionModal.setItems(page.elements.newsletterCategory.value.map((x: any) => x.name))
const data = {
  title: page?.elements.title.value,
  subtitle: page?.elements.subtitle.value,
  content: page?.elements.content.value,
  publishingDate: page?.elements.publishingDate.value,
  cta: page?.elements.callToAction.value,
  author: mapIntoLinkedItems(page?.elements?.authors?.value),
  speakers: mapIntoLinkedItems(page?.elements?.speakers?.value),
  presenters: mapIntoLinkedItems(page?.elements?.presenters?.value),
  capability: page?.elements.relatedCapabilities.value,
  insightCategory: page?.elements.insightCategory.value,
  eventStart: page?.elements.eventStart,
  eventEnd: page?.elements.eventEnd,
  richTextInterpreterContent: page?.elements?.richTextInterpreterRichText?.value,
  relatedPeople: mapIntoLinkedItems(page?.elements?.relatedPeople?.value),
  showSubscribeBtn: multipleChoiceToBoolean(page?.elements?.enableSubscribeModal)
}
function mapIntoLinkedItems(items: string[] | undefined) {
  if (!items) return []
  return items.map((item) => {
    return (
      pageStore.getLinkedItem<PeoplePageModel>(item) ??
      siteStore.getLinkedItem<PeoplePageModel>(item)
    )
  })
}
// Computed property to check if the insightCategory contains "Events" or "Webinar"
const isEventOrWebinar = computed(() => {
  return data.insightCategory.some(
    (category) =>
      category.name === taxonomies.insight_category.terms.events.name ||
      category.name === taxonomies.insight_category.terms.webinars.name
  )
})

const updateSuperscriptReference = () => {
  const superscriptTexts: string[] = []
  const superscripts = Array.from(document.querySelectorAll('.rich-text-content sup'))
  superscripts.forEach((sup) => {
    let {referenceId, referenceText, additionalText } = useSuperscriptParser(sup.textContent ?? '')
    const link = document.createElement('a')
    link.textContent = referenceText

    if (!superscriptTexts.includes(referenceId)) {
      superscriptTexts.push(referenceId)
      link.id = `p-ref-${referenceId}`
      link.addEventListener('click', (event) => {
        event.preventDefault()
        scrollToElement(`ref-${referenceId}`, 130)
      })
    } else {
      link.id = `ref-${referenceId}`
      link.addEventListener('click', (event) => {
        event.preventDefault()
        scrollToElement(`p-ref-${referenceId}`, 130)
      })
    }
    sup.innerHTML = ''
    sup.appendChild(link)
    sup.appendChild(document.createTextNode('\u00A0')) // appends whitespace
    if(additionalText.length > 0) {
      sup.appendChild(document.createTextNode(additionalText))
    }
  })
}
onMounted(() => {
  updateSuperscriptReference()
})
</script>
<style lang="scss" scoped>
.register-button {
  padding: 16px;
  gap: 6px;
}

:deep(.content-cta) {
  margin-top: 20px;
}

:deep(.rich-text-content) {
  sup {
    cursor: pointer;
    a {
      color: var(--theme-color) !important;
      text-decoration: underline !important;
    }
  }
}
</style>

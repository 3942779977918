<template>
  <div class="location-page">
    <PageContent :sidebar="true" :sidebar-table-of-content="false">
      <template #sidebar>
        <div class="sidebar-content">
          <NuxtLink class="btn btn-light back-button" to="/offices">
            <IconArrowLeft /> Offices
          </NuxtLink>
          <div class="location-page__overview-text-sidebar">
            <SmartLinkStandaloneElement :codename="type.elements.title.codename">
              <h1 class="location-page__title">{{ page.elements.title.value }}</h1>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.image.codename">
              <!-- Displays Asset Image -->
              <div class="location-page__image">
                <NuxtImg :src="page.elements.image?.value[0]?.url" provider="kontentAiProvider" />
              </div>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.content.codename">
              <!-- Displays Richtext that includes Image -->
              <div class="location-page__content">
                <RichText :value="page.elements.content.value" />
              </div>
            </SmartLinkStandaloneElement>
          </div>
          <SmartLinkStandaloneElement :codename="type.elements.contact.codename">
            <div class="office-partner">
              <b>
                <h4>Office Partner</h4>
              </b>
              <div v-for="contact in contactElements" :key="contact?.system.id">
                <PeopleCard
                  :item="
                    {
                      alias: contact.elements.peopleAlias?.value,
                      displayName: contact.elements.displayName?.value,
                      email: contact.elements.email?.value,
                      peopleImageUrl: contact.elements.peopleImage?.value[0]?.url,
                      location: contact.elements?.peopleLocation.value,
                      phone: contact.elements.peoplePhoneNumber?.value,
                      position: [contact.elements.peopleTitle.value[0]?.name],
                      id: contact?.system.id,
                      vCardUrl: contact.elements.vcard.value[0]?.url
                    } as SearchResultPeopleItem
                  "
                />
              </div>
            </div>
          </SmartLinkStandaloneElement>

          <div v-if="callToActionElements.length > 0" class="cta-button">
            <SmartLinkStandaloneElement :codename="type.elements.call_to_action.codename">
              <div v-for="cta in callToActionElements" :key="cta?.system.id">
                <CallToAction
                  :model="cta"
                  context-class="btn btn-manatt-black"
                  custom-target="_blank"
                  :has-icon="true"
                />
                <!-- <CallToAction
                    :model="cta"
                    contextClass="btn btn-manatt-black"
                    customTarget="_blank"
                    :hasIcon="true"
                  /> -->
              </div>
            </SmartLinkStandaloneElement>
          </div>

          <div v-if="page.elements.address.value.length > 0" class="address">
            <strong>
              <h4>Address</h4>
            </strong>
            <SmartLinkStandaloneElement :codename="type.elements.address.codename">
              <RichText :value="page.elements.address.value" />
            </SmartLinkStandaloneElement>
          </div>

          <div v-if="!isEmpty(page.elements.additionalAddress.value)" class="address">
            <strong>
              <h4>Address</h4>
            </strong>
            <SmartLinkStandaloneElement :codename="type.elements.additional_address.codename">
              <RichText :value="page.elements.additionalAddress.value" />
            </SmartLinkStandaloneElement>
          </div>

          <div
            v-if="
              page.elements.phoneNumber.value.length > 0 || page.elements.faxNumber.value.length > 0
            "
            class="location-page__contact"
          >
            <strong>
              <h4>Contact Details</h4>
            </strong>
            <div
              v-if="page.elements.phoneNumber.value.length > 0"
              class="location-page__contact__phone"
            >
              <IconPhone />
              <h6>
                <RichText :value="page.elements.phoneNumber.value" />
              </h6>
            </div>
            <div
              v-if="page.elements.faxNumber.value.length > 0"
              class="location-page__contact__fax"
            >
              <IconPrinter />
              <h6>
                <RichText :value="page.elements.faxNumber.value" />
              </h6>
            </div>
          </div>
          <div class="location-page__share">
            <ShareButtons />
          </div>
        </div>
      </template>

      <template #content>
        <div class="main-content">
          <div class="container location-page__overview-text">
            <SmartLinkStandaloneElement :codename="type.elements.title.codename">
              <h1 class="location-page__title">{{ page.elements.title.value }}</h1>
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.image.codename">
              <!-- Displays Asset Image -->
              <div class="location-page__image">
                <NuxtImg :src="page.elements.image?.value[0]?.url" provider="kontentAiProvider" />
              </div>
            
            </SmartLinkStandaloneElement>
            <SmartLinkStandaloneElement :codename="type.elements.content.codename">
              <!-- Displays Richtext that includes Image -->
              <div class="location-page__content">
                <RichText :value="page.elements.content.value" />
              </div>
            </SmartLinkStandaloneElement>
          </div>
        </div>
      </template>
    </PageContent>
  </div>
</template>

<script lang="ts" setup>
import { usePageStore } from '~/stores/page'
import CallToAction from '~/components/rich-texts/CallToAction.vue'
import RichText from '~/components/rich-texts/RichText.vue'
import type { CallToActionModel, PeoplePageModel, LocationPageModel } from '~/models'
import { contentTypes } from '~/models'
import { usePeopleModalStore } from '~/stores/modals/people-modal'
import type { SearchResultPeopleItem } from '~/types/SearchResultItem'

const type = contentTypes.location_page
const pageStore = usePageStore()
// const siteStore = useSiteStore()
const peopleModalStore = usePeopleModalStore()
const page = pageStore.page as LocationPageModel
const callToActionElements = pageStore.getListAs<CallToActionModel>(
  page.elements.callToAction.value
)
const contactElements = pageStore.getListAs<PeoplePageModel>(page.elements.contact.value)
</script>

<template>
  <div class="ventures-page" :class="getColor">
    <VenturesHero
      v-for="hero in pageStore.getListAs<InteriorHeroModel>(page.elements.hero.value)"
      :key="hero?.system.id"
      :model="hero"
    />
    <PageContent
      :hero-slot="true"
      :hero="pageStore.getListAs<InteriorHeroModel>(page.elements.hero.value).length > 0"
      :sidebar="!isEmpty(page.elements.sidebar.value)"
      :sidebar-table-of-content="false"
    >
      <template v-if="!isEmpty(page.elements.sidebar.value)" #sidebar>
        <div class="sidebar">
          <SmartLinkStandaloneElement :codename="type.elements.sidebar.codename">
            <RichText :value="page.elements.sidebar.value" />
          </SmartLinkStandaloneElement>
        </div>
      </template>
      <template #content>
        <div class="main-content">
          <SmartLinkStandaloneElement
            class="main-content__richtext"
            :codename="type.elements.main_content.codename"
          >
            <RichText :value="page.elements.mainContent.value" />
          </SmartLinkStandaloneElement>
        </div>
      </template>
    </PageContent>
  </div>
</template>
<script lang="ts" setup>
import type { InteriorHeroModel, InteriorPageModel } from '~/models'
import { contentTypes } from '~/models'
const type = contentTypes.interior_page

const pageStore = usePageStore()
const page = pageStore.page as InteriorPageModel

const getColor = () => {
  //blue, gold, green, magenta, orange, red
  return firstOrDefault(page.elements.color.value)?.codename
}
</script>

<template>
  <SmartLinkStandaloneElement :codename="type.elements.title.codename">
    <div class="career-opportunity-page">
      <Breadcrumb
        :items="[
          { url: '/', title: 'Home' },
          { url: '/careers', title: 'Careers' },
          { url: route.fullPath, title: page.elements.title.value ?? '' }
        ]"
        class="d-none d-lg-block"
      />
      <PageContent :sidebar="true" :sidebar-table-of-content="false">
        <template #sidebar>
          <div class="main-content-sidebar">
            <div v-if="page.elements.title.value">
              <SmartLinkStandaloneElement :codename="type.elements.title.codename">
                <h3>{{ page.elements.title.value }}</h3>
              </SmartLinkStandaloneElement>
            </div>
            <div v-if="page.elements.department.value.length > 0">
              <SmartLinkStandaloneElement :codename="type.elements.department.codename">
                <h6>Department: {{ page.elements.department.value[0]?.name }}</h6>
              </SmartLinkStandaloneElement>
            </div>
            <div v-if="page.elements.jobNumber.value">
              <SmartLinkStandaloneElement :codename="type.elements.job_number.codename">
                <h6>Job Number: {{ page.elements.jobNumber.value }}</h6>
              </SmartLinkStandaloneElement>
            </div>
            <div v-if="page.elements.jobNumber.value">
              <SmartLinkStandaloneElement :codename="type.elements.location.codename">
                <h6>Office{{}}: {{ officesText }}</h6>
              </SmartLinkStandaloneElement>
            </div>
            <br />
            <div v-if="page.elements.content.value">
              <SmartLinkStandaloneElement :codename="type.elements.content.codename">
                <span v-html="page.elements.content.value"></span>
              </SmartLinkStandaloneElement>
            </div>
          </div>
          <SmartLinkStandaloneElement :codename="type.elements.contact.codename">
            <div class="sidebar">
              <div v-if="contactElements.length > 0">
                <h3>Contact</h3>
                <ContactCard :model="contactElements[0]" />
              </div>
              <ShareButtons />
              <div v-if="callToActionElements.length > 0">
                <CallToAction
                  v-for="cta in callToActionElements"
                  :key="cta?.system.id"
                  :model="cta"
                  context-class="btn btn-manatt-black"
                />
              </div>
            </div>
          </SmartLinkStandaloneElement>
        </template>
        <template #content>
          <div class="main-content">
            <div v-if="page.elements.title.value">
              <SmartLinkStandaloneElement :codename="type.elements.title.codename">
                <h3>{{ page.elements.title.value }}</h3>
              </SmartLinkStandaloneElement>
            </div>
            <div v-if="page.elements.department.value.length > 0">
              <SmartLinkStandaloneElement :codename="type.elements.department.codename">
                <h6>Department: {{ page.elements.department.value[0]?.name }}</h6>
              </SmartLinkStandaloneElement>
            </div>
            <div v-if="page.elements.jobNumber.value">
              <SmartLinkStandaloneElement :codename="type.elements.job_number.codename">
                <h6>Job Number: {{ page.elements.jobNumber.value }}</h6>
              </SmartLinkStandaloneElement>
            </div>
            <div v-if="page.elements.jobNumber.value">
              <SmartLinkStandaloneElement :codename="type.elements.location.codename">
                <h6>Offices: {{ officesText }}</h6>
              </SmartLinkStandaloneElement>
            </div>
            <br />
            <div v-if="page.elements.content.value">
              <SmartLinkStandaloneElement :codename="type.elements.content.codename">
                <span v-html="page.elements.content.value"></span>
              </SmartLinkStandaloneElement>
            </div>
          </div>
        </template>
        <template #break>
          <div class="break-component">
            <div v-if="page.elements.department.value.length < 0">
              <SmartLinkStandaloneElement :codename="type.elements.featured_position.codename">
                <h6
                  ><strong>TEMP Featured Position Logic:</strong>
                  {{ page.elements.featuredPosition.value[0]?.name }}
                </h6>
                <h6 v-if="showIcon">Show Icon: Yes</h6>
                <h6 v-else>Show Icon: No</h6>
              </SmartLinkStandaloneElement>
            </div>

            <div v-if="page.elements.careerCategory.value.length < 0">
              <SmartLinkStandaloneElement :codename="type.elements.career_category.codename">
                <h6>Career Category: {{ page.elements.careerCategory.name }}</h6>
              </SmartLinkStandaloneElement>
            </div>
          </div>
        </template>
      </PageContent>
    </div>
  </SmartLinkStandaloneElement>
</template>

<script lang="ts" setup>
import { usePageStore } from '~/stores/page'
import ContactCard from '~/components/cards/ContactCard.vue'
import type {
  CallToActionModel,
  ContactCardModel,
  CareerOpportunitiesPageModel,
  LocationPageModel
} from '~/models'
import { multipleChoiceToBoolean } from '~/utils/kontent-helper'
import { contentTypes } from '~/models'
const type = contentTypes.career_opportunities_page

const pageStore = usePageStore()
const page = pageStore.page as CareerOpportunitiesPageModel
const callToActionElements = pageStore.getListAs<CallToActionModel>(
  page.elements.callToAction.value
)
const contactElements = pageStore.getListAs<ContactCardModel>(page.elements?.contact?.value)
const showIcon = computed(() => multipleChoiceToBoolean(page.elements?.featuredPosition))

const route = useRoute()
const officesText = computed(() => {
  return pageStore
    .getListAs<LocationPageModel>(page.elements?.location?.value)
    .map((item) => item?.elements?.title?.value)
    .join(', ')
})
</script>

<template>
  <div class="capabilities-page">
    <InteriorHero
      v-for="hero in pageStore.getListAs<InteriorHeroModel>(page.elements.hero.value)"
      :key="hero?.system.id"
      :model="hero"
    />
    <Breadcrumb :items="breadcrumbItems" class="d-none d-lg-block" />
    <PageContent
      :sidebar="true"
      :sidebar-table-of-content="true"
      :header-sub-navigation="true"
      sidebar-table-of-content-first-item-label="Overview"
    >
      <template #sidebar>
        <div class="sidebar">
          <SmartLinkStandaloneElement :codename="type.elements.leader.codename">
            <div class="contact-us">
              <SmartLinkStandaloneElement :codename="type.elements.overview_text.codename">
                <section class="container capabilities-page__overview-text-sidebar">
                  <h2 v-if="page.elements.overviewTitle.value">{{
                    page.elements.overviewTitle.value
                  }}</h2>
                  <template v-if="splitCharacterCount != 0">
                    <RichText :value="undefined" :portable-text="portableText1" />
                    <div :id="`collapse-${page.system.id}`" ref="showMore" class="collapse">
                      <RichText :value="undefined" :portable-text="portableText2" />
                    </div>
                    <button
                      v-if="splitCharacterCount != 0"
                      :id="`collapse-button-${page.system?.id}`"
                      class="btn btn-light px-0"
                      type="button"
                      aria-expanded="false"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#collapse-${page.system?.id}`"
                      :aria-controls="`collapse-${page.system?.id}`"
                    >
                      <template v-if="showMoreCollapsed">
                        Show More
                        <IconCaretDown height="20" />
                      </template>
                      <template v-else>
                        Show Less
                        <IconCaretUp height="20" />
                      </template>
                    </button>
                  </template>
                  <RichText v-else :value="page.elements.overviewText.value" />
                </section>
              </SmartLinkStandaloneElement>
              <PeopleCard
                v-for="contact in pageStore.getListAs<PeoplePageModel>(page.elements.leader.value)"
                :key="contact?.system.id"
                class="people-card capabilities-page__overview-text-sidebar-people"
                :item="
                  {
                    alias: contact?.elements?.peopleAlias?.value,
                    displayName: contact?.elements?.displayName?.value,
                    email: contact?.elements?.email?.value,
                    peopleImageUrl: contact?.elements?.peopleImage?.value[0]?.url,
                    location: contact?.elements?.peopleLocation?.value,
                    phone: contact?.elements?.peoplePhoneNumber?.value,
                    position: [contact?.elements?.peopleTitle?.value[0]?.name],
                    id: contact?.system?.id,
                    vCardUrl: contact.elements.vcard.value[0]?.url
                  } as SearchResultPeopleItem
                "
              />
              <div v-if="page.elements.leader.value.length > 0" class="divider" />
            </div>
          </SmartLinkStandaloneElement>
        </div>
      </template>
      <template #content>
        <div class="content">
          <SmartLinkStandaloneElement :codename="type.elements.overview_text.codename">
            <section class="container capabilities-page__overview-text">
              <h2 v-if="page.elements.overviewTitle.value">{{
                page.elements.overviewTitle.value
              }}</h2>
              <template v-if="splitCharacterCount != 0">
                <RichText :value="undefined" :portable-text="portableText1" />
                <div :id="`collapse-${page.system.id}`" ref="showMore" class="collapse">
                  <RichText :value="undefined" :portable-text="portableText2" />
                </div>
              </template>
              <RichText v-else :value="page.elements.overviewText.value" />
              <button
                v-if="splitCharacterCount != 0"
                :id="`collapse-button-${page.system?.id}`"
                class="btn btn-light px-0"
                type="button"
                aria-expanded="false"
                data-bs-toggle="collapse"
                :data-bs-target="`#collapse-${page.system?.id}`"
                :aria-controls="`collapse-${page.system?.id}`"
              >
                <template v-if="showMoreCollapsed">
                  Show More
                  <IconCaretDown height="20" />
                </template>
                <template v-else>
                  Show Less
                  <IconCaretUp height="20" />
                </template>
              </button>
            </section>
          </SmartLinkStandaloneElement>
          <SmartLinkStandaloneElement :codename="type.elements.content.codename">
            <LazyRichText :value="page.elements.content.value" />
          </SmartLinkStandaloneElement>
          <div
            v-if="page.elements.leader.value.length > 0"
            class="container capabilities-page__contact-us-mobile"
          >
            <PeopleCard
              v-for="contact in pageStore.getListAs<PeoplePageModel>(page.elements.leader.value)"
              :key="contact?.system.id"
              class="people-card"
              :item="
                {
                  alias: contact?.elements?.peopleAlias?.value,
                  displayName: contact?.elements?.displayName?.value,
                  email: contact?.elements?.email?.value,
                  peopleImageUrl: contact?.elements?.peopleImage?.value[0]?.url,
                  location: contact?.elements?.peopleLocation?.value,
                  phone: contact?.elements?.peoplePhoneNumber?.value,
                  position: [contact?.elements?.peopleTitle?.value[0]?.name],
                  id: contact?.system?.id,
                  vCardUrl: contact.elements.vcard.value[0]?.url
                } as SearchResultPeopleItem
              "
            />
          </div>
        </div>
      </template>
      <template #break>
        <SmartLinkStandaloneElement
          class="break-component"
          :codename="type.elements.break_component.codename"
        >
          <LazyRichText :value="page.elements.breakComponent.value" />
        </SmartLinkStandaloneElement>
      </template>
    </PageContent>
  </div>
</template>
<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'
import type { CapabilitiesPageModel, InteriorHeroModel, PeoplePageModel } from '~/models'
import { contentTypes } from '~/models'
import type { SearchResultPeopleItem } from '~/types/SearchResultItem'

const route = useRoute()
const type = contentTypes.capabilities_page
const pageStore = usePageStore()
const page = pageStore.page as CapabilitiesPageModel

const findParentBreadcrumb = (
  tree: any[],
  childTitle: string
): { url: string; title: string } | null => {
  for (const item of tree) {
    for (const subItem of item.subItems || []) {
      if (subItem.title === childTitle) {
        return { title: item.title, url: item.url }
      }
    }
    const result = findParentBreadcrumb(item.subItems || [], childTitle)
    if (result) {
      return result
    }
  }
  return null
}

let parentBreadcrumb = findParentBreadcrumb(
  useNavigationItemStore().items,
  page.elements.title.value ?? ''
)

if (
  parentBreadcrumb == null ||
  parentBreadcrumb.title == 'Services' ||
  parentBreadcrumb.title == 'Industries'
) {
  parentBreadcrumb = { url: '/', title: 'Home' }
}

const breadcrumbItems = [
  ...(parentBreadcrumb.title != 'Home' ? [{ url: '/', title: 'Home' }] : []),
  parentBreadcrumb,
  { url: route.fullPath, title: page.elements.title.value ?? '' }
]

const splitCharacterCount = page.elements.readMoreCharacterCount.value ?? 0
const { portableText1, portableText2 } = splitPortableText(
  splitCharacterCount,
  page.elements.overviewText.value
)

const { scrollToElementById } = useSmoothScroll()
const showMoreCollapsed = ref<boolean>(true)
const showMore = ref<HTMLElement | null>(null)
const initShowMore = () => {
  if (!showMore.value) return
  useEventListener(showMore.value, 'show.bs.collapse', () => {
    showMoreCollapsed.value = false
    const prev = showMore.value?.previousElementSibling as HTMLElement
    const firstChild = showMore.value?.firstElementChild as HTMLElement
    if (prev && firstChild && prev.tagName == 'UL' && firstChild.tagName == 'UL') {
      prev.style.marginBottom = '0px'
    }
  })
  useEventListener(showMore.value, 'hide.bs.collapse', () => {
    showMoreCollapsed.value = true
    scrollToElementById(page.system.id, 150, true)
  })
}

onMounted(() => {
  initShowMore()
})
</script>

<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent :id="model?.system?.id" :component="!model?.system?.workflow">
      <div class="person-info border p-3">
        <div v-if="model?.elements.name.value.length > 0">
          <SmartLinkStandaloneElement :codename="type.elements.name.codename">
            <strong>
              <h4><strong>{{ model?.elements.name.value }}</strong></h4>
            </strong>
          </SmartLinkStandaloneElement>
        </div>
        <div v-if="model?.elements.title.value.length > 0" class="title mb-3">
          <SmartLinkStandaloneElement :codename="type.elements.title.codename">
            <p>{{ model?.elements.title.value }}</p>
          </SmartLinkStandaloneElement>
        </div>
        <div v-if="model?.elements.address.value.length > 0" class="address mb-3">
          <SmartLinkStandaloneElement :codename="type.elements.address.codename">
            <RichText :value="model?.elements.address.value" />
          </SmartLinkStandaloneElement>
        </div>

        <div class="actions">
          <div v-if="model?.elements.phoneNumber.value.length > 0" class="d-flex mb-2">
            <IconPhone />
            <SmartLinkStandaloneElement :codename="type.elements.phone_number.codename">
              <span class="d-inline-block ms-1 contact-item">
                <RichText :value="model?.elements.phoneNumber.value" />
              </span>
            </SmartLinkStandaloneElement>
          </div>
          <div v-if="model?.elements.email.value.length > 0" class="d-flex mb-3">
            <IconEmail />
            <SmartLinkStandaloneElement :codename="type.elements.email.codename">
              <span class="d-inline-block ms-1 contact-item">
                <RichText :value="model?.elements.email.value" />
              </span>
            </SmartLinkStandaloneElement>
          </div>
        </div>
      </div>
    </SmartLinkItemComponent>
  </span>
</template>

<script lang="ts" setup>
import type { ContactCardModel } from '~/models'
import { contentTypes } from '~/models'
const type = contentTypes.contact_card

const props = defineProps<{ model: ContactCardModel }>()
</script>

<template>
  <div class="people-page">
    <Breadcrumb
      :items="[
        { url: '/', title: 'Home' },
        { url: '/people', title: 'People' },
        { url: route.fullPath, title: page.elements.title.value ?? '' }
      ]"
      class="d-none d-lg-block"
    />
    <PageContent
      :sidebar="true"
      :sidebar-table-of-content="true"
      sidebar-table-of-content-first-item-label="Bio"
    >
      <template #sidebar>
        <div class="sidebar">
          <SmartLinkStandaloneElement :codename="type.elements.title.codename">
            <div class="people-page__overview-text-sidebar">
              <SmartLinkStandaloneElement :codename="type.elements.title.codename">
                <h1>{{ data?.title }}</h1>
              </SmartLinkStandaloneElement>
              <div class="people-page__bio">
                <SmartLinkStandaloneElement :codename="type.elements.content_bio.codename">
                  <template v-if="splitCharacterCount != 0">
                    <RichText :value="undefined" :portable-text="portableText1" />
                    <div :id="`collapse-${page.system.id}`" ref="showMore" class="collapse">
                      <RichText :value="undefined" :portable-text="portableText2" />
                    </div>
                  </template>
                  <RichText v-else :value="data?.content" />
                  <button
                    v-if="splitCharacterCount != 0"
                    :id="`collapse-button-${page.system?.id}`"
                    class="btn btn-light"
                    type="button"
                    aria-expanded="false"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse-${page.system?.id}`"
                    :aria-controls="`collapse-${page.system?.id}`"
                  >
                    <template v-if="showMoreCollapsed">
                      Show More
                      <IconCaretDown />
                    </template>
                    <template v-else>
                      Show Less
                      <IconCaretUp />
                    </template>
                  </button>
                </SmartLinkStandaloneElement>
              </div>
            </div>
            <div class="contact-us">
              <PeopleCard
                :item="
                  {
                    alias: data.alias,
                    displayName: data.displayName,
                    email: data.email,
                    peopleImageUrl: data.image,
                    location: data.location,
                    phone: data.phoneNumber,
                    position: [data.position[0]?.name],
                    title: 'Contact Us',
                    id: page?.system.id,
                    vCardUrl: data.vCardUrl
                  } as unknown as SearchResultPeopleItem
                "
              />
            </div>
          </SmartLinkStandaloneElement>
          <div class="people-page__services">
            <h3>Services</h3>
            <span
              v-for="(capability, index) in data.capability"
              :key="capability.codename"
              @click="goToServices(capability.codename)"
              >{{ capability.name }}{{ index < data.capability.length - 1 ? ', ' : '' }}</span
            >
          </div>
          <div class="people-page__offices">
            <h3>Offices</h3>
            <span v-for="(office, index) in offices" :key="office" @click="goToOffices(office)"
              >{{ office }}{{ index < offices.length - 1 ? ', ' : '' }}</span
            >
          </div>
        </div>
      </template>
      <template #content>
        <div class="main-content">
          <div class="container people-page__overview-text">
            <SmartLinkStandaloneElement :codename="type.elements.title.codename">
              <h1>{{ data?.title }}</h1>
            </SmartLinkStandaloneElement>
            <div class="people-page__bio">
              <SmartLinkStandaloneElement :codename="type.elements.content_bio.codename">
                <template v-if="splitCharacterCount != 0">
                  <RichText :value="undefined" :portable-text="portableText1" />
                  <div :id="`collapse-${page.system.id}`" ref="showMore" class="collapse">
                    <RichText :value="undefined" :portable-text="portableText2" />
                  </div>
                </template>
                <RichText v-else :value="data?.content" />
                <button
                  v-if="splitCharacterCount != 0"
                  :id="`collapse-button-${page.system?.id}`"
                  class="btn btn-light"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#collapse-${page.system?.id}`"
                  :aria-controls="`collapse-${page.system?.id}`"
                >
                  <template v-if="showMoreCollapsed">
                    Show More
                    <IconCaretDown />
                  </template>
                  <template v-else>
                    Show Less
                    <IconCaretUp />
                  </template>
                </button>
              </SmartLinkStandaloneElement>
            </div>
          </div>
          <div class="related-content">
            <SmartLinkStandaloneElement :codename="type.elements.content_related_content.codename">
              <RichText :value="data?.relatedContent" />
            </SmartLinkStandaloneElement>
          </div>
        </div>
      </template>
      <template #break>
        <div class="break-component">
          <SmartLinkStandaloneElement :codename="type.elements.break_component.codename">
            <RichText :value="page.elements.breakComponent.value" />
          </SmartLinkStandaloneElement>
        </div>
      </template>
    </PageContent>
  </div>
</template>
<script setup lang="ts">
import { useEventListener } from '@vueuse/core'
import type { PeoplePageModel, LocationPageModel } from '~/models'
import { contentTypes } from '~/models'
import type { SearchResultPeopleItem } from '~/types/SearchResultItem'
const type = contentTypes.people_page

const route = useRoute()
const pageStore = usePageStore()
const page = pageStore.page as PeoplePageModel
const data = {
  content: page?.elements?.contentBio?.value,
  title: page?.elements?.title?.value,
  readMoreCharCount: page?.elements?.contentReadMore?.value,
  capability: page?.elements?.contentPrimaryCapabilities?.value,
  relatedContent: page?.elements?.contentRelatedContent?.value,
  relatedCapabilities: page?.elements?.contentCapabilities?.value,
  firstName: page?.elements?.firstName?.value,
  lastName: page?.elements?.lastName?.value,
  displayName: page?.elements?.displayName?.value,
  alias: page?.elements?.peopleAlias?.value,
  image: page?.elements?.peopleImage?.value[0]?.url,
  phoneNumber: page?.elements?.peoplePhoneNumber?.value,
  email: page?.elements?.email?.value,
  location: page?.elements?.peopleLocation?.value,
  position: page?.elements?.peopleTitle?.value,
  vCardUrl: page?.elements.vcard?.value[0]?.url
}

const offices = computed(() => {
  return pageStore
    .getListAs<LocationPageModel>(data.location)
    .map((item) => item?.elements.title.value)
})

const splitCharacterCount = page.elements.contentReadMore.value ?? 0
const { portableText1, portableText2 } = splitPortableText(splitCharacterCount, data.content)

const { scrollToElementById } = useSmoothScroll()
const showMoreCollapsed = ref<boolean>(true)
const showMore = ref<HTMLElement | null>(null)
const initShowMore = () => {
  if (!showMore.value) return
  useEventListener(showMore.value, 'show.bs.collapse', () => {
    showMoreCollapsed.value = false
    const prev = showMore.value?.previousElementSibling as HTMLElement
    const firstChild = showMore.value?.firstElementChild as HTMLElement
    if (prev && firstChild && prev.tagName == 'UL' && firstChild.tagName == 'UL') {
      prev.style.marginBottom = '0px'
    }
  })
  useEventListener(showMore.value, 'hide.bs.collapse', () => {
    showMoreCollapsed.value = true
    scrollToElementById(page.system.id, 150, true)
  })
}

function goToOffices(office: string) {
  const officesCodename = pageStore
    .getListAs<LocationPageModel>(data.location)
    ?.find((l) => l.elements.title.value === office)?.system.codename

  if (officesCodename) {
    pageStore.getUrlByCodename(officesCodename).then((url) => {
      navigateTo(url)
    })
  }
}

async function goToServices(codename: string) {
  const capabilityResponse = await $fetch(`/api/get-kontent-items/capability`, {
    method: 'GET',
    params: {
      capability: codename
    }
  })
  const capability = firstOrDefault(capabilityResponse.data?.items)
  if (capability) {
    pageStore.getUrlByCodename(capability.system.codename).then((url) => {
      navigateTo(url)
    })
  }
}
onMounted(() => {
  initShowMore()
})
</script>

<template>
  <SeoMetatags />
  <SmartLinkItem v-if="data" :item-id="data?.system?.id">
    <component :is="activeComponent" v-if="activeComponent" />
  </SmartLinkItem>
  <ScriptTags />
</template>

<script lang="ts" setup>
import { contentTypes } from '~/models'
import { useCssVar } from '@vueuse/core'
import { useCDNHeaders } from '#nuxt-multi-cache/composables'
import {
  CapabilitiesPage,
  CareerOpportunityPage,
  InsightPage,
  InteriorPage,
  VenturesPage,
  LocationPage,
  PeoplePage
} from '#components'

const pageStore = usePageStore()
const route = useRoute()
const config = useRuntimeConfig()

if (config.enableCdn) {
  useCDNHeaders((helper) => {
    helper
      .public()
      .setNumeric('maxAge', Number(config.cacheMaxAge))
      .setNumeric('staleIfError', 43200)
  })
}

let itemId = ''
if (route.query && !config.public.isProduction) {
  if (route.query.itemid) itemId = route.query?.itemid as string
}

const { data } = await useAsyncData(`page-${route.path}_${itemId}`, async () => {
  if (pageStore.info) {
    await pageStore.fetchData()
    return pageStore.page
  }
  return null
})

if (!data.value) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found'
  })
}

const activeComponent: any = computed(() => {
  if (data.value) {
    const resolvedComponent = resolveComponent(data.value.system.type) as any
    if (resolvedComponent instanceof Function) return resolvedComponent()
    return resolvedComponent
  }
})

const resolveComponent = (type: string) => {
  switch (type) {
    case contentTypes.interior_page.codename:
      return () => {
        const isVentures = useRoute().fullPath.startsWith('/manatt-ventures')
        return isVentures ? VenturesPage : InteriorPage
      }
    case contentTypes.location_page.codename:
      return LocationPage
    case contentTypes.capabilities_page.codename:
      return CapabilitiesPage
    case contentTypes.career_opportunities_page.codename:
      return CareerOpportunityPage
    case contentTypes.people_page.codename:
      return PeoplePage
    case contentTypes.insight_page.codename:
      return InsightPage
    default:
      return null
  }
}

function setThemeColorVariables() {
  let pageColor = pageStore.getThemeColor()
  if (pageStore.pageInfo?.type == 'people_page') {
    pageColor = 'gold'
  }
  const hexColor = useCssVar(`--theme-${pageColor}`).value
  const themeColor = new ThemeColor(hexColor)
  const style = document.documentElement.style
  style.setProperty('--theme-color', themeColor.hex)
  style.setProperty('--theme-color-r', themeColor.r.toString())
  style.setProperty('--theme-color-g', themeColor.g.toString())
  style.setProperty('--theme-color-b', themeColor.b.toString())
  style.setProperty('--theme-link-color', themeColor.hex)
  if (pageColor == 'black') {
    const goldHex = useCssVar(`--theme-gold`).value
    const goldTheme = new ThemeColor(goldHex)
    style.setProperty('--theme-link-color', goldTheme.hex)
  }
}

onMounted(() => setThemeColorVariables())
useRouter().beforeEach(() => {
  setThemeColorVariables()
})
</script>

<template>
  <span :id="model?.system?.id">
    <SmartLinkItemComponent
      :id="model?.system?.id"
      :component="!model?.system?.workflow"
      class="ventures-hero"
    >
      <template v-if="data.slides.length == 0">
        <picture>
          <source
            v-for="(image, i) in lqipImages"
            :key="`${model?.system?.id}-src-${i}`"
            :media="lqipImagesMedia[i]"
            :srcset="image"
          />
          <NuxtImg :src="lqipImageDefault" class="hero-background-lqip" />
        </picture>
        <div ref="bgContainer" class="hero-background-container">
          <div
            class="wrapper hero-background-overlay"
            :style="{ 'background-image': `url(${reactiveImageUrl})` }"
          >
            <div class="container d-flex flex-column align-items-center">
              <div class="col">
                <div class="caption">
                  <SmartLinkStandaloneElement :codename="type?.elements?.title?.codename">
                    <h1 class="pt-5 pb-2">
                      {{ model?.elements.title.value }}
                    </h1>
                  </SmartLinkStandaloneElement>

                  <SmartLinkStandaloneElement :codename="type?.elements?.content?.codename">
                    <p class="lead">{{ model?.elements.content.value }}</p>
                  </SmartLinkStandaloneElement>

                  <SmartLinkStandaloneElement :codename="type?.elements?.title?.codename">
                    <div class="cta">
                      <CallToAction
                        v-for="cta in pageStore.getListAs<CallToActionModel>(
                          model?.elements.cta.value
                        )"
                        :key="cta?.system?.id"
                        :model="cta"
                        class="btn btn-primary"
                      />
                    </div>
                  </SmartLinkStandaloneElement>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <SmartLinkStandaloneElement :codename="type.elements.hero_slides.codename">
          <div class="wrapper container-fluid interior-hero__container">
            <div class="col">
              <div id="homeHero" ref="carousel" class="carousel slide">
                <SmartLinkItemComponent
                  :id="model?.system?.id"
                  :component="!model?.system?.workflow"
                  class="carousel-inner"
                >
                  <HeroSlide
                    v-for="(slide, index) in data.slides"
                    :key="slide?.system?.id"
                    :index="index"
                    :is-active="carouselActiveSlide == index"
                    :model="slide"
                  />
                </SmartLinkItemComponent>
                <div class="carousel-indicators">
                  <button
                    v-for="(_slide, index) in data.slides"
                    :key="`carousel-indicators-${model?.system?.id}-${index}`"
                    :aria-current="carouselActiveSlide == index"
                    :aria-label="`Slide ${index}`"
                    :class="{ active: carouselActiveSlide == index }"
                    :data-bs-slide-to="index"
                    :disabled="carouselIsAnimating"
                    data-bs-target="#homeHero"
                    type="button"
                  />
                </div>
              </div>
            </div>
          </div>
        </SmartLinkStandaloneElement>
      </template>
    </SmartLinkItemComponent>
  </span>
</template>
<script lang="ts" setup>
import type { CallToActionModel, InteriorHeroModel, InteriorHeroSlideModel } from '~/models'
import { contentTypes } from '~/models'
import { useElementSize, useEventListener } from '@vueuse/core'
import get from 'lodash/get'

const type = contentTypes.interior_hero

const pageStore = usePageStore()
const props = defineProps<{ model: InteriorHeroModel }>()
const data = {
  isSlidesEmpty: get(props, 'model.elements.heroSlides.value', []).length == 0,
  slides: pageStore.getListAs<InteriorHeroSlideModel>(props.model?.elements.heroSlides.value)
}

const bgContainer = ref<HTMLElement>()
const { width, height } = useElementSize(bgContainer)
const { reactiveImageUrl } = useImageSourceReactive(
  props.model?.elements?.asset?.value[0]?.url,
  width,
  height
)

const {
  imageDefault: lqipImageDefault,
  images: lqipImages,
  imagesMedia: lqipImagesMedia
} = useImageSourceset(props.model?.elements?.asset?.value[0]?.url, 2000, 600, true)

useHead({
  link: [
    ...(lqipImages.value.map((image) => {
      return {
        rel: 'preload',
        href: image,
        as: 'image',
        fetchpriority: 'high',
        media: lqipImagesMedia.value[lqipImages.value.indexOf(image)]
      }
    }) as Array<Record<string, any>>)
  ]
})

const carousel = ref<HTMLElement | null>(null)
const carouselIsAnimating = ref(false)
const carouselActiveSlide = ref(0)

const updateActiveSlide = () => {
  if (carousel.value) {
    // update carousel 'active index' this way because
    //  it needs to be set after the transition animation is completed
    const activeItem = carousel.value.querySelector('.carousel-item.active')
    const items = Array.from(carousel.value.querySelectorAll('.carousel-item'))
    carouselActiveSlide.value = items.indexOf(activeItem as Element)
    carouselIsAnimating.value = false
  }
}

onMounted(() => {
  if (carousel.value) {
    useEventListener(carousel.value, 'slide.bs.carousel', () => (carouselIsAnimating.value = true))
    useEventListener(carousel.value, 'slid.bs.carousel', updateActiveSlide)
    updateActiveSlide()
  }
})
</script>

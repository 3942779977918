<template>
    <div class="share-buttons">
        <h3>Share</h3>
        <div class="share-buttons__icons">
            <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" title="Share to Facebook" target="_blank">
                <IconFacebook />
            </a>
            <a :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`" title="Share to LinkedIn"
                target="_blank">
                <IconLinkedIn />
            </a>
            <a :href="`https://twitter.com/intent/tweet?url=${url}`" title="Share to X" target="_blank">
                <IconXTwitter />
            </a>
        </div>
    </div>
</template>
<script lang="ts" setup>
const pageStore = usePageStore()
const config = useRuntimeConfig()
const url = ref<string>('')
pageStore.getUrlByItemId(pageStore.pageId).then((value) => {
    url.value = config.public.apiBase + value
})

</script>

<template>
   <div class="event-details">
    <h3>Event Details</h3>
    <div class="event-details-info">
      <div class="date">
        <IconCalendarDots />
        {{ formatDate(eventStart.value) }}
      </div>
      <div class="time" v-if="eventStart.value && eventEnd?.value">
        <IconClock />
        {{ formatedTimeRange(eventStart.value, eventStart.displayTimeZone ,eventEnd.value) }}
      </div>
      <div class="time" v-else-if="eventStart.value">
        <IconClock />
        {{ formatedTime(eventStart.value, eventStart.displayTimeZone) }}
      </div>
    </div>
    <button
        v-if="showSubscribeBtn"
        @click="subscriptionModal.toggleModal()"
        class="btn btn-manatt-black register-button"
        >
        Subscribe
    </button>
   </div>
</template>
<script setup lang="ts">
import { formatDate, formatedTimeRange } from "~/utils/string-helper";
import type { Elements } from '@kontent-ai/delivery-sdk';
import { usePardotModalStore } from '~/stores/modals/pardot-modal'
import IconClock from "../icons/IconClock.vue";

const subscriptionModal = usePardotModalStore()
const props = defineProps<{ eventStart: Elements.DateTimeElement; eventEnd?: Elements.DateTimeElement; showSubscribeBtn?:boolean }>();

</script>